import React, { useState } from "react"
import PropTypes from "prop-types"
import PrimaryLayout from "@src/layouts/primary"
import { UserContext } from "@src/context/user-context"
import PageHelmet from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveSection from "@src/components/container-responsive-section"
import Form, { FormActions } from "@src/components/form"
import FormFieldEmailAddress from "@src/components/form-field-email"
import FormFieldCreatePassword from "@src/components/form-field-password-create"
import Button from "@src/components/core-button"
import Link from "@src/components/core-link"

const AccountSignupPage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const [email, setEmail] = useState(params.get("email"))
  const [success, setSuccess] = useState()
  const [formMessage, setFormMessage] = useState()
  return (
    <PrimaryLayout>
      <PageHelmet title={normalizeTitle("Signup")} />
      <UserContext.Consumer>
        {({ signup }) => {
          const handleSubmit = ({ email, password }) => {
            signup(email, password)
              .then(() => {
                setSuccess(true)
                setFormMessage()
              })
              .catch(error => {
                setSuccess(false)
                setFormMessage(error)
              })
          }
          return success ? (
            <ResponsiveSection variant="card">
              <h1>Account created!</h1>
              <p>Please check your email for further instructions.</p>
              <p>
                <Link to="/account/verification/">
                  Click here to verify your account
                </Link>
              </p>
            </ResponsiveSection>
          ) : (
            <ResponsiveSection variant="card">
              <h1>Sign up</h1>
              <Form
                name="user-signup-page"
                onSubmit={handleSubmit}
                variant="blue"
                netlify
              >
                <FormFieldEmailAddress
                  id="email"
                  name="email"
                  placeholder="Please enter your email address"
                  label="Email address"
                  defaultValue={email}
                  onChange={setEmail}
                  validation={{
                    required:
                      "We'll need your email address to create an account",
                  }}
                />
                <FormFieldCreatePassword
                  id="password"
                  name="password"
                  label="Password"
                  placeholder="Set your password"
                />
                <FormActions>
                  {formMessage && <p className="form-error">{formMessage}</p>}
                  <Button variant="rounded" type="submit">
                    Create Account
                  </Button>
                  <p>
                    Already have an account?{" "}
                    <Link to="/account/login/">Log in now</Link>
                  </p>
                </FormActions>
              </Form>
            </ResponsiveSection>
          )
        }}
      </UserContext.Consumer>
    </PrimaryLayout>
  )
}
AccountSignupPage.propTypes = {
  location: PropTypes.object,
}
export default AccountSignupPage
